import { getQuoteCompletePets, getQuotePets, useQuote } from '@pumpkincare/quotes';
import { formatISODate, getEffectiveDate, getIsLoggedIn } from '@pumpkincare/shared';
import { Body2, LegalBody, Typography } from '@pumpkincare/shared/ui';

import Summary from './summary';

import styles from './plan-review.css';

function PlanReview() {
  const { data: quoteData } = useQuote();
  const pets = getIsLoggedIn()
    ? getQuotePets(quoteData)
    : getQuoteCompletePets(quoteData);

  const hasPrevent = pets.some(pet => pet.hasPrevent);

  return (
    <>
      <Summary />

      <div>
        <h4>Important Dates</h4>

        <div>
          <div>
            <Body2 isBold className={styles.dateSpacing}>
              {formatISODate(new Date(), { format: 'MMMM Do, YYYY' })}
            </Body2>

            <div className={styles.verticalLine}>
              <LegalBody className={styles.importantDatesFont}>
                <span className={Typography.bold}>Enrollment Date</span>
                <br />
                Yay! Today you decided to enroll in a Pumpkin plan and join the
                Pumpkin Pack.
              </LegalBody>
            </div>
          </div>

          <div>
            <Body2 isBold className={styles.dateSpacing}>
              {getEffectiveDate()}
            </Body2>

            <div className={styles.verticalLine}>
              <LegalBody className={styles.importantDatesFont}>
                <span className={Typography.bold}>
                  Insurance Policy Effective Date
                </span>
                <br />
                The day after you enroll in pet insurance, and the day your waiting
                period starts.
              </LegalBody>
            </div>

            {hasPrevent ? (
              <div className={styles.verticalLine}>
                <LegalBody className={styles.importantDatesFont}>
                  <br />
                  <span className={Typography.bold}>
                    Preventive Essentials Start Date
                  </span>
                  <br />
                  The day after you enroll in Preventive Essentials and when you can
                  use those benefits.
                </LegalBody>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default PlanReview;
