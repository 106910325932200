import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { postAnswers, useQuestion } from '@pumpkincare/identity';
import {
  getQuoteActivePet,
  getQuoteIdentityId,
  getQuoteIsRegistered,
  useQuote,
} from '@pumpkincare/quotes';
import { getIsLoggedIn, useBooleanInput } from '@pumpkincare/shared';
import { LegalBody } from '@pumpkincare/shared/ui';

import { Paths } from '../../../app-shell';
import { getQuotesActiveId } from '../../selectors';
import Interstitial from '../interstitial';
import QuoteFlowHeader from '../quote-flow-header';
import { QuoteFlowTransition } from '../shared';
import MultipleChoice from './multiple-choice';
import YesNo from './yes-no';

import styles from './pet-questions.css';

export const YES_NO = 'Boolean';
export const INTERSTITIAL = 'Interstitial';
export const MULTIPLE_CHOICE = 'Multiple Choice - Single Answer';

export const answerOptionsComponentMap = {
  [YES_NO]: YesNo,
  [MULTIPLE_CHOICE]: MultipleChoice,
};

function PetQuestions() {
  const history = useHistory();

  const activeId = useSelector(getQuotesActiveId);

  const { data: quoteData } = useQuote();
  const identityId = getQuoteIdentityId(quoteData);
  const isRegistered = getQuoteIsRegistered(quoteData);
  const activePet = getQuoteActivePet(quoteData)(activeId);
  const { petBreedSpecies } = activePet ?? {};

  const {
    data: questionData,
    isFetched: isQuestionFetched,
    refetch: refetchQuestion,
  } = useQuestion(identityId, petBreedSpecies);

  const answer = useRef([]);
  const [isProcessing, toggleProcessing] = useBooleanInput(true);

  const {
    answer_options,
    id: questionVariantId,
    img_src,
    variant_type,
    variant_text,
    subtitle,
    module,
  } = questionData;
  const AnswerOptionsComponent = answerOptionsComponentMap[variant_type];

  useEffect(() => {
    /*
      if user returns to page with id already loaded, QuoteFlowComponent
      transition, (and handleTransitionExit) isn't triggered. toggleProcessing to
      re-enable buttons

      https://petlife.atlassian.net/browse/PK-1047
     */
    if (isProcessing && questionVariantId) {
      toggleProcessing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isQuestionFetched && questionVariantId === '') {
      if (isRegistered || getIsLoggedIn()) {
        history.push(Paths.PlanSelection);
      } else {
        history.push(Paths.Register);
      }
    }
  }, [history, isQuestionFetched, isRegistered, questionVariantId]);

  const subtitleView = subtitle ? (
    <LegalBody className={styles.subtitle}>{subtitle}</LegalBody>
  ) : null;
  const questionView = (
    <>
      <QuoteFlowHeader>{variant_text}</QuoteFlowHeader>

      {subtitleView}

      {AnswerOptionsComponent ? (
        <AnswerOptionsComponent
          data={answer_options}
          disabled={isProcessing}
          currentAnswer={answer.current}
          onChange={handleAnswersChange}
          autoFocus
        />
      ) : null}
    </>
  );

  function handleAnswersChange(data) {
    toggleProcessing();
    answer.current = Array.isArray(data) ? data : [data];

    postAnswers(identityId, questionVariantId, answer.current).then(refetchQuestion);
  }

  function handleNextClick() {
    toggleProcessing();

    postAnswers(identityId, questionVariantId, answer.current).then(refetchQuestion);
  }

  function handleTransitionExit() {
    if (isProcessing) {
      toggleProcessing();
      answer.current = [];
    }
  }

  return (
    // use 'id' to trigger transition
    <QuoteFlowTransition
      location={{ key: questionVariantId }}
      onTransitionExit={handleTransitionExit}
    >
      {variant_type === INTERSTITIAL ? (
        <Interstitial
          petImage={img_src}
          text1={variant_text}
          text2={subtitle}
          disabled={isProcessing}
          onClick={handleNextClick}
          module={module}
        />
      ) : (
        questionView
      )}
    </QuoteFlowTransition>
  );
}

export default PetQuestions;
