import { useDispatch, useSelector } from 'react-redux';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import classNames from 'classnames';

import { MC_URL } from '@pumpkincare/config';
import {
  Body1,
  Body2,
  InputStyles,
  LegalBody,
  Typography,
} from '@pumpkincare/shared/ui';
import { getPaymentMethodLast4, usePayment } from '@pumpkincare/user';

import { setUpdatedQuotePaymentField } from '../../../../../quotes';
import { getQuotesPaymentInfo } from '../../../../../quotes/selectors';
import { useErrors } from '../../../checkout-provider';

import styles from './payment-form.css';

const STYLE_OVERWRITE = {
  base: {
    fontSize: '16px',
    fontFamily: 'Nunito Sans',
    color: 'var(--scoutNavy)',
    '::placeholder': { color: 'var(--pusheenGray)' },
    ':focus::placeholder': { color: 'transparent' },
  },
};
// !important doesnt work as a css overwrite

function PaymentForm() {
  const dispatch = useDispatch();

  const paymentInfoFields = useSelector(getQuotesPaymentInfo);
  const { cardNumber, cvc, expirationDate, nameShownOnCard } = paymentInfoFields;

  const errors = useErrors();

  const { data: paymentData, isFetching: isPaymentFetching } = usePayment();
  const last4 = getPaymentMethodLast4(paymentData);

  const textInputClassName = classNames(
    styles.textInput,
    styles.border,
    Typography.body2,
    InputStyles.text
  );

  function handleFieldChangeFactory(field) {
    return ({ error, complete }) => {
      if (
        ['nameShownOnCard', 'cardNumber', 'expirationDate', 'cvc'].includes(field)
      ) {
        dispatch(
          setUpdatedQuotePaymentField({
            field: field,
            error: !complete,
            empty: !complete,
          })
        );
      }

      if (error && error.message) {
        errors.current = { ...errors.current, [field]: error.message };
      } else if (complete) {
        delete errors.current[field];
      }
    };
  }

  return (
    <div>
      <LegalBody style={{ margin: '16px 0 0' }}>
        Enter any valid credit or debit card. We currently do not accept pre-paid
        cards. All fields are required.
      </LegalBody>

      <div style={{ marginTop: '32px' }}>
        {isPaymentFetching ? null : last4 ? (
          <Body2>
            <input
              className={textInputClassName}
              disabled
              placeholder={'Debit or Credit Card Number'}
              aria-label={'Debit or Credit Card Number'}
              id={'last4'}
              style={{ width: '100%' }}
              value={`•••• •••• •••• ${last4}`}
            />

            <div>
              If you’d like to change your credit card information, please visit{' '}
              <a href={`${MC_URL}/account/billing`}>Billing page</a>
            </div>
          </Body2>
        ) : (
          <>
            <Body1 className={styles.inputLabel}>
              <label htmlFor={'nameShownOnCard'}>Name on Card</label>
            </Body1>

            <input
              className={classNames(
                textInputClassName,
                nameShownOnCard.error ? styles.error : null
              )}
              id={'nameShownOnCard'}
              name={'nameShownOnCard'}
              autoComplete={'cc-name'}
              onChange={e => {
                dispatch(
                  setUpdatedQuotePaymentField({
                    field: 'nameShownOnCard',
                    value: e.target.value,
                  })
                );

                return handleFieldChangeFactory('nameShownOnCard')({
                  error: e.target.value
                    ? false
                    : {
                        message: 'Your name on card field is incomplete.',
                      },
                  complete: !!e.target.value,
                });
              }}
            />

            <Body1 className={styles.inputLabel}>Card Number</Body1>

            <CardNumberElement
              id='cardNumber'
              name='cardNumber'
              autocomplete='cc-number'
              className={classNames(textInputClassName, {
                [styles.formInvalid]: cardNumber.error,
              })}
              onChange={handleFieldChangeFactory('cardNumber')}
              options={{
                iconStyle: 'solid',
                showIcon: true,
                classes: {
                  base: styles.base,
                  focus: styles.focus,
                  invalid: styles.invalid,
                },
                style: STYLE_OVERWRITE,
              }}
            />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div className={styles.divColumnLeft}>
                <Body1 className={styles.inputLabel}>Expiration Date</Body1>
                <CardExpiryElement
                  id={'expirationDate'}
                  name='expirationDate'
                  autocomplete='cc-exp'
                  className={classNames(textInputClassName, {
                    [styles.formInvalid]: expirationDate.error,
                  })}
                  onChange={handleFieldChangeFactory('expirationDate')}
                  options={{
                    iconStyle: 'solid',
                    showIcon: true,
                    classes: {
                      base: styles.base,
                      focus: styles.focus,
                      invalid: styles.invalid,
                    },
                    style: STYLE_OVERWRITE,
                  }}
                />
              </div>

              <div className={styles.divColumnRight}>
                <Body1 className={styles.inputLabel}>CVC</Body1>

                <div style={{ position: 'relative' }}>
                  <CardCvcElement
                    id={'cvc'}
                    name={'cvc'}
                    autocomplete='cc-csc'
                    className={classNames(textInputClassName, {
                      [styles.formInvalid]: cvc.error,
                    })}
                    onChange={handleFieldChangeFactory('cvc')}
                    options={{
                      iconStyle: 'solid',
                      showIcon: true,
                      classes: {
                        base: styles.base,
                        focus: styles.focus,
                        invalid: styles.invalid,
                      },
                      style: STYLE_OVERWRITE,
                    }}
                  />

                  <img
                    alt=''
                    src={'/assets/images/iconImages/backcard.png'}
                    style={{
                      position: 'absolute',
                      top: '12px',
                      right: '10px',
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default PaymentForm;
