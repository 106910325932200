import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { usePetPlanData } from '@pumpkincare/plans';
import {
  getPetPolicy,
  getQuoteActivePet,
  getQuoteId,
  getQuoteVetId,
  useMutatePetPolicy,
  useQuote,
} from '@pumpkincare/quotes';
import { DOG, UP_TO_5_MONTHS, UP_TO_11_MONTHS } from '@pumpkincare/shared';
import { Body2, Typography } from '@pumpkincare/shared/ui';

import { getIdentityId } from '../../../../identity';
import { getQuotesActiveId } from '../../../../quotes/selectors';
import InsurancePicker from './insurance-picker';

import styles from './picker.css';

function Picker({ onAddToCart, buttonText = 'Add to Cart', image }) {
  const { punks594BreedSpeciesOnPlanPage } = useFlags();

  const activeId = useSelector(getQuotesActiveId);
  const identityId = useSelector(getIdentityId);

  const { data: quoteData } = useQuote();
  const quoteId = getQuoteId(quoteData);
  const activePet = getQuoteActivePet(quoteData)(activeId);
  const vetId = getQuoteVetId(quoteData);
  const { petName } = activePet;

  const { mutate: mutatePetPolicy } = useMutatePetPolicy();

  const { isReady, data: petPlanData } = usePetPlanData({
    petId: activePet.id,
    identityId,
    vetId,
  });

  const { activeValues } = petPlanData;

  const profileImage = !punks594BreedSpeciesOnPlanPage
    ? `/assets/images/photos/plan-selection/${getProfileImage(
        activePet?.petAge,
        activePet?.petBreedSpecies
      )}`
    : image;

  function getProfileImage(petAge, petBreedSpecies) {
    if (petBreedSpecies === DOG) {
      return petAge === UP_TO_5_MONTHS || petAge === UP_TO_11_MONTHS
        ? 'profile-image-puppy.png'
        : 'profile-image-dog.png';
    } else {
      return petAge === UP_TO_5_MONTHS || petAge === UP_TO_11_MONTHS
        ? 'profile-image-kitten.png'
        : 'profile-image-cat.png';
    }
  }

  function handleInsuranceChange({ key, value }) {
    mutatePetPolicy({
      pet: { ...activePet, [key]: value },
    });
  }

  useEffect(() => {
    getPetPolicy(quoteId, activePet.id)
      // mutate pet policy selection with recommended options right away
      .then(() => mutatePetPolicy({ pet: { ...activePet, ...activeValues } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h2>{petName}'s plan</h2>

        <span className={Typography.body2}>
          A pet insurance plan fit for your super lovable pup {petName}.
        </span>
      </div>

      <div className={styles.pickerSectionGrid}>
        <div className={styles.imagesGrid}>
          <div className={styles.imagesWrapper}>
            <img src={profileImage} alt='' className={styles.profileModuleImage} />

            <div className={styles.costBlurbImage}>
              <img
                src='/assets/images/iconImages/piggy-bank.png'
                alt=''
                className={styles.piggyImg}
              />

              <Body2>
                Your {activePet.petBreedSpecies.toLowerCase()}’s breed, age & ZIP +
                the reimbursement rate, annual limit & deductible you choose all
                factor into your cost!
              </Body2>
            </div>
          </div>
        </div>

        <div className={styles.pickerCompGrid}>
          <div className={styles.pickerContainer}>
            {isReady ? (
              <InsurancePicker
                onAddToCart={onAddToCart}
                buttonText={buttonText}
                petPlanData={petPlanData}
                onChange={handleInsuranceChange}
                pet={activePet}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

Picker.defaultProps = {
  buttonText: 'Add to Cart',
  headerSplit: {},
  image: '',
};

Picker.propTypes = {
  buttonText: PropTypes.string,
  headerSplit: PropTypes.shape({
    start: PropTypes.string,
    adjectives: PropTypes.string,
  }),
  image: PropTypes.string,
};
export default Picker;
