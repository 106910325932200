import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  analyticsIdentify,
  GAQuoteFlowSubmitNameAndEmail,
  setTrackedCustomProps,
} from '@pumpkincare/analytics';
import { HOME_URL } from '@pumpkincare/config';
import { getDiscountId, useDiscount } from '@pumpkincare/discounts';
import {
  getMarketingAttribution,
  setMarketingAttributionProperty,
} from '@pumpkincare/marketing';
import {
  getQuoteId,
  patchQuoteVetAttribution,
  registerQuote,
  useQuote,
} from '@pumpkincare/quotes';
import { useBooleanInput } from '@pumpkincare/shared';

import { getAppNoopPayload, Paths } from '../../../app-shell';
import { getQuotesActiveId, getQuotesTrackingId } from '../../selectors';

function useSubmitRegister() {
  const { data: quoteData } = useQuote();
  const quoteId = getQuoteId(quoteData);

  const { data: discountData } = useDiscount();
  const discountId = getDiscountId(discountData);

  const noopPayload = useSelector(getAppNoopPayload);
  const activeId = useSelector(getQuotesActiveId);
  const trackingId = useSelector(getQuotesTrackingId);

  const history = useHistory();

  const [isLoading, toggleIsLoading] = useBooleanInput(false);

  function submit(payload) {
    const { email, firstName, lastName, policyZipCode, vet } = payload;

    GAQuoteFlowSubmitNameAndEmail();
    setTrackedCustomProps({ email });
    analyticsIdentify(payload, trackingId);

    // update vet info
    analyticsIdentify(
      vet.id
        ? {
            VETCLINICNAME: vet.vet_name,
            VETCLINICID: vet.id,
          }
        : {
            VETCLINICNAME: vet.vet_name ? 'Other Vet' : 'No Vet',
          },
      trackingId
    );

    const params = {
      quoteId,
      activeId,
      trackingId,
      firstName,
      lastName,
      email,
      policyZipCode,
      discountId,
      noopPayload,
    };

    if (vet?.id) {
      patchQuoteVetAttribution(quoteId, { vet_id: vet.id });
    }

    toggleIsLoading();

    return registerQuote(params).then(() => {
      const marketing = getMarketingAttribution();
      const traits = {
        QUOTEURL: `${HOME_URL}/plan-selection/${quoteId}`,
        QUOTEID: quoteId,
        email,
        marketing,
      };

      toggleIsLoading();

      analyticsIdentify(traits, trackingId);
      setMarketingAttributionProperty({ isGoingToPlanSelection: true });

      history.push(Paths.PlanSelection);
    });
  }

  return { submit, isLoading };
}

export default useSubmitRegister;
