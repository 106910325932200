import { LegalBody } from '@pumpkincare/shared/ui';

import styles from './plan-mid-page-navbar.css';

function PlanMidPageNavbar({
  whatsRef,
  whatsNotCovRef,
  whyPetInsRef,
  howItWorksRef,
  policyState,
}) {
  function scrollToView(ref) {
    if (ref.current) {
      ref.current.style.scrollMargin = '40px';
      ref.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h5 className={styles.title}>Let’s dig in!</h5>

        <div className={styles.navSection}>
          <button className={styles.navItem}>
            <LegalBody
              isBold
              className={styles.navItemText}
              onClick={() => scrollToView(whatsRef)}
            >
              What’s Covered
            </LegalBody>
          </button>

          {policyState !== 'ME' ? (
            <button className={styles.navItem}>
              <LegalBody
                isBold
                className={styles.navItemText}
                onClick={() => scrollToView(whatsNotCovRef)}
              >
                What’s Not Covered
              </LegalBody>
            </button>
          ) : null}

          <button className={styles.navItem}>
            <LegalBody
              isBold
              className={styles.navItemText}
              onClick={() => scrollToView(howItWorksRef)}
            >
              How It Works
            </LegalBody>
          </button>

          <button className={styles.navItem}>
            <LegalBody
              isBold
              className={styles.navItemText}
              onClick={() => scrollToView(whyPetInsRef)}
            >
              Why Pet Insurance?
            </LegalBody>
          </button>
        </div>
      </div>
    </div>
  );
}

export default PlanMidPageNavbar;
