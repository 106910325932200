import classNames from 'classnames';

import { Body2, Typography } from '@pumpkincare/shared/ui';

import { NextButton, QuoteFlowComponentContent } from '../shared';

import styles from './interstitial.module.css';

export default function Interstitial({
  petImage,
  text1 = '',
  text2 = '',
  onClick,
  disabled,
  module = null,
}) {
  return (
    <>
      <QuoteFlowComponentContent className={styles.interstitial}>
        {module ? (
          <>
            <img src={module.mainImageSrc.content.value} alt='' />

            <p className={styles.header}>
              <b>{module.header.content.value}</b>
            </p>

            <div className={styles.content}>
              <div
                className={classNames(styles.body, Typography.body2)}
                dangerouslySetInnerHTML={{
                  __html: module.htmlBody.content.value,
                }}
              />
            </div>
          </>
        ) : (
          <>
            <img src={petImage} alt='' />

            <Body2 className={styles.text}>
              <b>{text1}</b>
              <br />
              {text2}
            </Body2>
          </>
        )}
      </QuoteFlowComponentContent>

      <NextButton onClick={onClick} disabled={disabled} tabIndex={0} autoFocus />
    </>
  );
}
