import ReactPixel from 'react-facebook-pixel';
import * as Sentry from '@sentry/browser';
import { loadStripe } from '@stripe/stripe-js/pure';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { withLDConsumer, withLDProvider } from 'launchdarkly-react-client-sdk';
import { compose } from 'redux';

import {
  getAnonymousId,
  load3Bears,
  loadGoogleAnalytics,
  loadOneTrust,
  loadSegment,
  loadSprig,
  runQueuedExperiments,
  runQueuedTracks,
} from '@pumpkincare/analytics';
import {
  ENVIRONMENT,
  FACEBOOK_PIXEL_ID,
  initLogRocket,
  NOOP_STRIPE_API_KEY,
  STRIPE_API_KEY,
} from '@pumpkincare/config';
import { captureException } from '@pumpkincare/shared';

import { setTrackingId } from '../quotes';
import { getQuotesTrackingId } from '../quotes/selectors';
import { getAppNoopPayload } from './state/selectors/app-selectors';
import getPageTitle from './utils/get-page-title';

import packageJson from '../../package.json';

export function configureApp({ getState, dispatch }) {
  if (ENVIRONMENT === 'production') {
    Sentry.init({
      environment: ENVIRONMENT,
      release: packageJson.version,
      dsn: 'https://a78e04b173e6488c8e65670944bd20a9@sentry.io/1496371',
    });

    ReactPixel.init(FACEBOOK_PIXEL_ID, {}, { debug: false, autoConfig: false });

    initLogRocket('jnr91r/pumpkin-quote-flow-getpumpkin');
  }

  initTimeOutScripts(dispatch, getState);
}

// any scripts that don't need to run on initial load should be here
function initTimeOutScripts(dispatch, getState) {
  const onSegmentReady = () => {
    // trackingId might be in a quote we're fetching, we won't overwrite it
    if (getQuotesTrackingId(getState()) === null) {
      dispatch(setTrackingId(getAnonymousId()));
    }

    runQueuedTracks();
    runQueuedExperiments({ analytics: true });
  };

  setTimeout(() => {
    loadSegment(onSegmentReady, getPageTitle(document.location.pathname));
    loadGoogleAnalytics();
    load3Bears();
    loadSprig();
    loadOneTrust();
  }, 200);
}

export function initStripe(dispatch, state) {
  const key = getAppNoopPayload(state)?.no_op ? NOOP_STRIPE_API_KEY : STRIPE_API_KEY;

  return loadStripe(key).catch(captureException);
}

export function initLaunchDarkly(clientSideID, user) {
  return {
    withLaunchDarkly: compose(
      withLDProvider({ clientSideID, user }),
      withLDConsumer()
    ),
    ldClient: LDClient.initialize(clientSideID, user),
  };
}
