import { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GAQuoteFlowSubmitPetBreed } from '@pumpkincare/analytics';
import { useQuote } from '@pumpkincare/quotes';
import {
  captureException,
  DOG,
  FEMALE,
  GENERIC_ERROR,
  MALE,
  PET_AGE_OPTIONS,
  sortByKey,
  useBanners,
  useBooleanInput,
  useBreeds,
} from '@pumpkincare/shared';
import { Select, ToggleButton } from '@pumpkincare/shared/ui';

import { getMediaIsXsDown } from '../../../app-shell';
import { getQuotesActivePet } from '../../selectors';
import submitPetInfo from '../../thunks/submit-pet-info';
import QuoteFlowHeader from '../quote-flow-header';
import { NextButton, QuoteFlowComponentContent } from '../shared';

import styles from './pet-info.module.css';

function PetInfo() {
  const dispatch = useDispatch();
  const { petAge, petGender, petName, petBreedCode, petBreedSpecies } =
    useSelector(getQuotesActivePet);
  const isMobile = useSelector(getMediaIsXsDown);

  const { addBanner, removeAllBanners } = useBanners();

  // force React Query quote re fetch
  useQuote();

  const { data: breedsDataRaw } = useBreeds(petBreedSpecies);
  const breedsData = useMemo(
    () => sortByKey(breedsDataRaw, 'name'),

    [breedsDataRaw]
  );

  const boyIcon =
    petBreedSpecies === DOG
      ? '/assets/images/quote-flow/pet-info/dog-boy.png'
      : '/assets/images/quote-flow/pet-info/cat-boy.png';
  const girlIcon =
    petBreedSpecies === DOG
      ? '/assets/images/quote-flow/pet-info/dog-girl.png'
      : '/assets/images/quote-flow/pet-info/cat-girl.png';

  const breedOptions = breedsData.map(breed => {
    const { name, code, species, type } = breed;

    return {
      label: name,
      value: { code, species, type },
    };
  });
  const activePetBreed =
    petBreedCode && breedOptions.find(obj => obj.value.code === petBreedCode);
  const activePetAge = petAge && PET_AGE_OPTIONS.find(obj => obj.value === petAge);

  const [gender, setGender] = useState(petGender);
  const [breed, setBreed] = useState(
    activePetBreed ? { ...activePetBreed.value, name: activePetBreed.label } : null
  );
  const [age, setAge] = useState(activePetAge ? activePetAge.value : null);
  const [isSubmitting, toggleIsSubmitting] = useBooleanInput(false);

  useEffect(() => {
    setGender(petGender);
  }, [petGender]);

  function handleGenderSelection(value) {
    setGender(value);
  }

  function handleAgeSelection({ value }) {
    setAge(value);
  }

  function handleBreedSelection({ label, value: { code, type, species } }) {
    setBreed({ name: label, code, type, species });
  }

  function handleNextClick() {
    const { name, code, type, species } = breed;

    GAQuoteFlowSubmitPetBreed(name);

    removeAllBanners();
    toggleIsSubmitting();

    dispatch(
      submitPetInfo({
        petBreedName: name,
        petBreedCode: code,
        petBreedType: type,
        petBreedSpecies: species,
        petAge: age,
        petGender: gender,
      })
    ).catch(err => {
      captureException(err);

      toggleIsSubmitting();
      addBanner(GENERIC_ERROR);
    });
  }

  return (
    <Fragment>
      <QuoteFlowHeader>Let’s get some basic info about {petName}.</QuoteFlowHeader>

      <QuoteFlowComponentContent>
        <div
          className={styles.breedSelection}
          data-testid={'breed-autocomplete-wrapper'}
        >
          <Select
            isSearchable
            onChange={handleBreedSelection}
            placeholder='Select breed or start typing'
            label='Breed'
            options={breedOptions}
            defaultValue={activePetBreed}
            isMobile={isMobile}
            id={'id-select-breed'}
            autoFocus
          />
        </div>

        <div
          className={styles.ageSelection}
          data-testid={'age-autocomplete-wrapper'}
        >
          <Select
            options={PET_AGE_OPTIONS}
            onChange={handleAgeSelection}
            label='Age'
            placeholder='Select age'
            defaultValue={activePetAge}
            isMobile={isMobile}
            id={'id-select-age'}
          />
        </div>

        <div className={styles.genderChoice}>
          <ToggleButton
            classes={{ root: styles.genderChoiceItem }}
            role='radio'
            label={'Boy'}
            icon={boyIcon}
            selected={gender === MALE}
            data-testid={'boy-button'}
            clickHandler={() => handleGenderSelection(MALE)}
          />

          <ToggleButton
            classes={{ root: styles.genderChoiceItem }}
            role='radio'
            label={'Girl'}
            icon={girlIcon}
            selected={gender === FEMALE}
            data-testid={'girl-button'}
            clickHandler={() => handleGenderSelection(FEMALE)}
          />
        </div>

        <NextButton
          disabled={!(breed && age && gender)}
          onClick={handleNextClick}
          isLoading={isSubmitting}
        />
      </QuoteFlowComponentContent>
    </Fragment>
  );
}

export default PetInfo;
